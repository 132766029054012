import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { CONFIGURATION, Configuration } from "src/configuration";
import { Observable, of, throwError } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { catchError, concatMap, map, shareReplay } from "rxjs/operators";
import { WorkspaceStatus } from "./workspaces.constants";

const apiErrorCodes = {
  unableToGetSAMAccoutName: 1001,
  unableToGetActiveDirectoryDetails: 1002,
  badAuthToken: 1003,
  noDesktopRolesAssigned: 1004,
  workspaceAlreadyExists: 1005,
  errorCreatingWorkspace: 1006,
  errorDeletingWorkspace: 1007,
  errorStartingWorkspace: 1008,
  userDoesNotExist: 1009,
  errorGettingWorkspaceLoginDetails: 1010,
  errorGettingWorkspaceList: 1011,
  errorGettingUserWorkspace: 1012,
  unableToResolveInfrastructureContext: 1013,
  unableToProceedFurtherDueToMaintenance: 1014,
  workspacesFeatureNotAvailable: 1015,
};

const apiErrorsMap = Object.fromEntries(Object.entries(apiErrorCodes).map((a) => a.reverse()));

@Injectable({
  providedIn: "root",
})
export class WorkspaceService {
  private featureEnabled$: Observable<boolean> | null;

  constructor(
    private httpClient: HttpClient,
    @Inject(CONFIGURATION) private configuration: Configuration,
    private translate: TranslateService
  ) { }

  get workspacesBaseUrl(): Observable<string> {
    if (!this.configuration.apis.workspaces) {
      return throwError(
        () =>
        ({
          error: apiErrorCodes.workspacesFeatureNotAvailable,
        } as HttpErrorResponse)
      );
    }
    return of(this.configuration.apis.workspaces);
  }

  handleError(error: HttpErrorResponse): Observable<never | HttpErrorResponse> {
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      return throwError(() => `Error: ${error.error.message}`);
    } else {
      // Server-side errors
      if (error.status === 404) {
        return of(null);
      }
      const errorCode = parseInt(error.error, 10);
      const apiErrorTranslationKey = apiErrorsMap[errorCode] ?? "default";
      return this.translate.get(`errors.api.${apiErrorTranslationKey}`).pipe(
        map((message: string) => {
          throw new Error(message);
        })
      );
    }
  }

  featureEnabled(): Observable<boolean> {
    if (this.featureEnabled$) {
      return this.featureEnabled$;
    }

    this.featureEnabled$ = this.workspacesBaseUrl.pipe(
      concatMap((url) => this.httpClient.get(`${url}/featurestatus`, { observe: "response" })),
      catchError((error) => {
        const errorCode = parseInt(error.error, 10);
        if (
          [
            apiErrorCodes.workspacesFeatureNotAvailable,
            apiErrorCodes.unableToResolveInfrastructureContext,
            apiErrorCodes.unableToProceedFurtherDueToMaintenance,
          ].includes(errorCode)
        ) {
          return of(false);
        }
        return throwError(() => error);
      }),
      catchError(this.handleError.bind(this)),
      map((response: HttpResponse<any>) => response.status === 200), //eslint-disable-line @typescript-eslint/no-explicit-any
      shareReplay()
    );
    return this.featureEnabled$;
  }

  startWorkspace(): Observable<void> {
    return this.workspacesBaseUrl.pipe(
      concatMap((url) => this.httpClient.get<void>(`${url}/workspaces/start`)),
      catchError(this.handleError.bind(this))
    );
  }

  getUserWorkspaceStatus(): Observable<WorkspaceStatus> {
    return this.workspacesBaseUrl.pipe(
      concatMap((url) =>
        this.httpClient.get<WorkspaceDetails>(`${url}/workspaces/mine`, { observe: "response" })
      ),
      catchError(this.handleError.bind(this)),
      map((response: HttpResponse<WorkspaceDetails>) => {
        if (!response || response.status === 404) {
          return WorkspaceStatus.NoWorkspace;
        }

        if (response.status === 202) {
          return WorkspaceStatus.Unknown;
        }

        if (response.status === 200) {
          const workspaceDetails = response.body;
          return workspaceDetails.state as WorkspaceStatus;
        }

        return WorkspaceStatus.Unknown;
      })
    );
  }

  downloadFileByKey(fileKey: string): Observable<HttpResponse<Blob>> {
    return this.workspacesBaseUrl.pipe(
      concatMap((url) =>
        this.httpClient.get<Blob>(`${url}/GetFile?fileKey=${fileKey}`, {
          responseType: "blob" as "json",
          observe: "response",
        })
      ),
      catchError(this.handleError.bind(this))
    );
  }

  getWorkspaces(): Observable<WorkspaceUser[]> {
    return this.workspacesBaseUrl.pipe(
      concatMap((url) =>
        this.httpClient.get<WorkspaceUser[]>(`${url}/workspaces`, { observe: "response" })
      ),
      catchError(this.handleError.bind(this)),
      map((response: HttpResponse<WorkspaceUser[]>) => {
        response.body.forEach((x) => {
          x.disabled =
            x.state === WorkspaceStatus.Terminating ||
            x.state === WorkspaceStatus.Terminated ||
            x.state === WorkspaceStatus.Suspended;
        });

        return response.body;
      })
    );
  }

  createUserWorkspace(): Observable<object> {
    return this.workspacesBaseUrl.pipe(
      concatMap((url) => this.httpClient.post(`${url}/workspaces`, {})),
      catchError(this.handleError.bind(this))
    );
  }

  removeWorkspace(workspaceId: string): Observable<object> {
    return this.workspacesBaseUrl.pipe(
      concatMap((url) => this.httpClient.delete(`${url}/workspaces/${workspaceId}`)),
      catchError(this.handleError.bind(this))
    );
  }

  getWorkspaceLoginDetails(): Observable<UserData> {
    return this.workspacesBaseUrl.pipe(
      concatMap((url) => this.httpClient.get<UserData>(`${url}/launch`)),
      map((data: UserData) => {
        if (
          !(
            typeof data.userName === "string" &&
            typeof data.userPassword === "string" &&
            typeof data.directoryRegistrationCode === "string"
          )
        ) {
          throw new TypeError(
            "one or more fields are missing or their values are null or empty in the response"
          );
        }
        return data;
      }),
      catchError(this.handleError.bind(this))
    );
  }
}

interface WorkspaceDetails {
  state: string;
}

export interface WorkspaceUser {
  workspaceId: string;
  username: string;
  state: string;
  computeType: string;
  disabled: boolean;
}

export interface UserData {
  userName: string;
  userPassword: string;
  directoryRegistrationCode: string;
}
