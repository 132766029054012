<con-page-layout>
  <div class="flex-container center" *ngIf="!loaded">
    <mat-spinner style="align-self: center" color="primary"></mat-spinner>
  </div>

  <div *ngIf="loaded">
    <con-page-header
      header=" {{ 'batch_jobs.view_batch_job.page_title' | translate }}:
        {{ jobData.title }}"
    >
      <div class="create-button">
        <button mat-raised-button routerLink="../../editbatchjob/{{ jobData.id }}"><mat-icon slot="icon">edit</mat-icon> Edit</button>
      </div>
      <div class="create-button">
        <button mat-raised-button (click)="executeNow(jobData.id, jobData.title)">
          {{ "batch_jobs.view_batch_job.execute_now_button_title" | translate }}
        </button>
      </div>
    </con-page-header>
    <xng-breadcrumb [separator]="iconTemplate"></xng-breadcrumb>
    <ng-template #iconTemplate>
      <mat-icon>chevron_right</mat-icon>
    </ng-template>
    <h4>{{ "batch_jobs.batch_job_form.batch_job_details_title" | translate }}</h4>
    <con-page-layout-content *ngIf="loaded">
      <mat-card>
        <mat-card-content>
          <div style="display: flex">
            <div>
              <mat-card-subtitle>
                {{ "batch_jobs.batch_job_form.batch_job_title" | translate }}
              </mat-card-subtitle>
              <p id=" row-spacing">{{ jobData.title }}</p>
              <mat-card-subtitle>{{ "batch_jobs.batch_job_form.description_title" | translate }} </mat-card-subtitle>
              <p id="row-spacing">{{ jobData.description }}</p>
              <mat-card-subtitle>{{ "batch_jobs.batch_job_form.project_name_title" | translate }} </mat-card-subtitle>
              <p>{{ jobData.projectName }}</p>
            </div>
            <div style="margin-top: 0%; margin-left: 435px">
              <mat-card-subtitle>{{ "batch_jobs.batch_job_form.job_type_title" | translate }} </mat-card-subtitle>
              <p id="row-spacing">{{ jobData.jobType }}</p>
              <mat-card-subtitle>
                {{ "batch_jobs.batch_job_form.batch_job_createDate" | translate }}
              </mat-card-subtitle>
              <p>{{ jobData.createdAt }}</p>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <h4>{{ "batch_jobs.batch_job_form.schedule_details_title" | translate }} ({{ timeZone }})</h4>
      <mat-card>
        <mat-card-content class="restrict-width">
          <mat-card-subtitle>{{ "batch_jobs.batch_job_form.cron_expression_title" | translate }} </mat-card-subtitle>
          <div style="display: flex">
            <p style="margin-top: 25px; margin-right: 500px">{{ jobData.humanReadableSchedule }}</p>
            <mat-checkbox disabled [(ngModel)]="jobData.enableSchedule">
              {{ "batch_jobs.manage_batch_jobs.enabled_text" | translate }}
            </mat-checkbox>
          </div>
        </mat-card-content>
      </mat-card>
      <h4>{{ "batch_jobs.batch_job_form.batch_job_parameters" | translate }}</h4>
      <mat-card>
        <mat-card-content>
          <mat-card-subtitle>{{ "batch_jobs.view_batch_job.pml_macro_title" | translate }} </mat-card-subtitle>
          <p id="row-spacing">{{ jobData.pmlFileName }}</p>
          <mat-card-subtitle>{{ "batch_jobs.view_batch_job.pml_credential_file_title" | translate }} </mat-card-subtitle>
          <p>{{ jobData.pmlCredentialsFileName }}</p>
        </mat-card-content>
      </mat-card>

      <h4>{{ "batch_jobs.view_batch_job.history_of_execution_title" | translate }}</h4>
      <mat-card>
        <ng-container *ngIf="dataSource.data.length > 0; then logExecutions; else noLogExecutions"></ng-container>
        <ng-template #logExecutions>
          <mat-card-content>
            <mat-table #table [dataSource]="dataSource">
              <!-- DateTime column  -->
              <ng-container matColumnDef="dateTime">
                <mat-header-cell fxFlex="60px" *matHeaderCellDef>
                  {{ "batch_jobs.view_batch_job.datetime_title" | translate }} ({{ timeZone }})</mat-header-cell
                >
                <mat-cell fxFlex="60px" *matCellDef="let element; let row">
                  <a routerLink="../../listexecutionhistory/{{ jobData.id }}/{{ row.executionTime }}">{{ element.formattedDate }}</a>
                </mat-cell>
              </ng-container>

              <!-- Job Execution Status Column -->
              <ng-container matColumnDef="status">
                <mat-header-cell fxFlex="60px" *matHeaderCellDef>
                  {{ "batch_jobs.view_batch_job.status_title" | translate }}</mat-header-cell
                >
                <mat-cell fxFlex="60px" *matCellDef="let element">
                  {{
                    element.status === JobStatus.Succeeded
                      ? ("batch_jobs.view_batch_job.status_succeeded" | translate)
                      : ("batch_jobs.view_batch_job.status_failed" | translate)
                  }}
                </mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedColumns"> </mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
            </mat-table>
            <mat-paginator #paginator [pageIndex]="0" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]"> </mat-paginator>
          </mat-card-content>
        </ng-template>
        <ng-template #noLogExecutions>
          <mat-card-content>
            <p>{{ "batch_jobs.view_batch_job.no_execution_history_message" | translate }}</p>
          </mat-card-content>
        </ng-template>
      </mat-card>
    </con-page-layout-content>
  </div>
</con-page-layout>
