<con-page-layout *ngIf="titleFetched">
  <con-page-header header="{{ 'batch_jobs.list_execution_history.page_title' | translate }}: {{ jobTitle }}"> </con-page-header>
  <xng-breadcrumb [separator]="iconTemplate" class="breadcrumb"></xng-breadcrumb>
  <ng-template #iconTemplate>
    <mat-icon>chevron_right</mat-icon>
  </ng-template>
  <con-page-layout-content>
    <div class="flex-container center" *ngIf="!loaded">
      <mat-spinner class="spinner-align" color="primary"></mat-spinner>
    </div>
    <div class="flex-container" *ngIf="loaded">
      <form #form="ngForm" [formGroup]="datePickerForm">
        <div style="display: flex">
          <div style="padding-right: 5%">
            <mat-form-field appearance="outline">
              <input
                readonly
                matInput
                [ngxMatDatetimePicker]="picker1"
                placeholder="Start Date"
                type="text"
                formControlName="startDate"
                #startDateInput
                (dateChange)="areFilterDatesValid(startDateInput.value, null)"
                [max]="maxDate"
              />
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker
                #picker1
                [showSpinners]="true"
                [showSeconds]="false"
                [stepHour]="1"
                [stepMinute]="1"
                [stepSecond]="1"
                [touchUi]="false"
                [color]="color"
                [enableMeridian]="true"
              >
              </ngx-mat-datetime-picker>
            </mat-form-field>
          </div>
          <div style="padding-right: 5%">
            <mat-form-field appearance="outline">
              <input
                readonly
                matInput
                [ngxMatDatetimePicker]="picker2"
                placeholder="End Date"
                type="text"
                formControlName="endDate"
                #endDateInput
                (dateChange)="areFilterDatesValid(null, endDateInput.value)"
                [max]="maxDate"
              />
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker
                #picker2
                [showSpinners]="true"
                [showSeconds]="false"
                [stepHour]="1"
                [stepMinute]="1"
                [stepSecond]="1"
                [touchUi]="false"
                [color]="color"
                [enableMeridian]="true"
              >
              </ngx-mat-datetime-picker>
            </mat-form-field>
          </div>
          <div style="padding-top: 5px">
            <button
              mat-raised-button
              class="filter-button"
              type="button"
              (click)="searchByDate(datePickerForm.value)"
              color="primary"
              [disabled]="!filterValid"
            >
              {{ "batch_jobs.list_execution_history.filter" | translate }}
            </button>
          </div>
        </div>
      </form>

      <mat-card>
        <mat-card-content>
          <mat-table #table [dataSource]="dataSource">
            <!-- LogName column  -->
            <ng-container matColumnDef="logName">
              <mat-header-cell fxFlex="60px" *matHeaderCellDef>
                {{ "batch_jobs.view_log_details.log_file_name" | translate }}</mat-header-cell
              >
              <mat-cell fxFlex="60px" *matCellDef="let element; let row">
                <a class="hand-cursor" (click)="navigateToViewLogsPage(row.executionTime, row.logFileName)">{{ element.logFileName }}</a>
              </mat-cell>
            </ng-container>

            <!-- Execution Time Column -->
            <ng-container matColumnDef="lastRunName">
              <mat-header-cell fxFlex="60px" *matHeaderCellDef>
                {{ "batch_jobs.view_log_details.execution_schedule" | translate }} ({{ timeZone }})</mat-header-cell
              >
              <mat-cell fxFlex="60px" *matCellDef="let element">{{ element.formattedExecutionTime }} </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"> </mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
          </mat-table>
        </mat-card-content>
      </mat-card>
    </div>
  </con-page-layout-content>
</con-page-layout>
