<con-page-layout>
  <div class="flex-container center" *ngIf="!loaded">
    <mat-spinner style="align-self: center" color="primary"></mat-spinner>
  </div>

  <con-page-header
    #pageHeader
    header="{{ 'batch_jobs.edit_batch_job.page_title' | translate }}:
    {{ jobData.title }}"
    *ngIf="loaded"
  >
  </con-page-header>
  <xng-breadcrumb [separator]="iconTemplate"></xng-breadcrumb>
  <ng-template #iconTemplate>
    <mat-icon>chevron_right</mat-icon>
  </ng-template>
  <con-page-layout-content *ngIf="loaded">
    <h3>{{ "batch_jobs.batch_job_form.batch_job_details_title" | translate }}</h3>
    <form #form="ngForm" [formGroup]="editJobForm" *ngIf="editJobForm; else errorPage" id="editJobForm">
      <mat-card>
        <mat-card-content>
          <mat-form-field [hideRequiredMarker]="true">
            <mat-label>{{ "batch_jobs.batch_job_form.batch_job_title" | translate }}</mat-label>
            <input matInput formControlName="title" />
          </mat-form-field>

          <mat-form-field apperance="fill">
            <mat-label>{{ "batch_jobs.batch_job_form.description_title" | translate }} (optional) </mat-label>
            <textarea rows="4" cols="50" matInput formControlName="description"></textarea>
          </mat-form-field>

          <mat-form-field [hideRequiredMarker]="true">
            <mat-label>{{ "batch_jobs.batch_job_form.project_name_title" | translate }}</mat-label>
            <input matInput formControlName="projectName" />
          </mat-form-field>
        </mat-card-content>
      </mat-card>

      <h3>{{ "batch_jobs.batch_job_form.schedule_details_title" | translate }}</h3>

      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{ scheduleDescription }}</strong>
          </mat-panel-title>

          <mat-panel-description class="flex-display">
            {{
              (panelOpenState ? "batch_jobs.batch_job_form.modify_schedule" : "batch_jobs.batch_job_form.click_modify_schedule") | translate
            }}

            <mat-checkbox formControlName="enableSchedule" class=".">
              <strong>{{ "batch_jobs.manage_batch_jobs.enabled_text" | translate }}</strong>
            </mat-checkbox>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-card-content class="restrict-width">
          <cron-editor class="restrict-width" [options]="cronOptions" [(cron)]="newSchedule" formControlName="schedule"> </cron-editor>
        </mat-card-content>
        <div class="flex-display">
          <h3>{{ newScheduleDescription | translate }}</h3>
        </div>
      </mat-expansion-panel>

      <h3>{{ "batch_jobs.batch_job_form.batch_job_parameters" | translate }}</h3>
      <mat-card>
        <mat-card-content>
          <div class="restrict-width">
            <mat-form-field appearance="fill" class="jobtype-dropdown" [hideRequiredMarker]="true">
              <mat-label style="min-width: 251px">{{ "batch_jobs.batch_job_form.job_type_title" | translate }}</mat-label>
              <mat-select Class="panel-custom-width" formControlName="jobType">
                <mat-option *ngFor="let option of jobTypes" [value]="option">
                  {{ "batch_jobs.jobTypes." + option | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div id="parameter">
            <input type="file" class="file-input" accept=".pmlmac" (change)="onPmlFileSelected($event.target.files[0])" #pmlFileUpload />
            <div class="file-upload-input-field">
              <mat-form-field>
                <div class="flex-display">
                  <input readonly matInput formControlName="pmlMacro" [value]="pmlFileName" placeholder="PML macro file" id="fileInput" />
                  <mat-error *ngIf="!isPmlMacFile">{{ "batch_jobs.batch_job_form.pml_file_type_error" | translate }} </mat-error>
                  <ng-container *ngIf="pmlError; then pmlErrorState; else pmlOkState"> </ng-container>
                  <ng-template #pmlOkState>
                    <div class="green-tick" *ngIf="pmlFileUploadSuccess">
                      <mat-icon>{{ "batch_jobs.batch_job_form.browse_file_upload_success_icon" | translate }}</mat-icon>
                    </div>
                  </ng-template>
                  <ng-template #pmlErrorState>
                    <mat-icon style="color: red">{{ "batch_jobs.batch_job_form.browse_file_upload_failure_icon" | translate }}</mat-icon>
                  </ng-template>
                </div>
              </mat-form-field>
            </div>
            <button mat-stroked-button id="upload-buttons" (click)="pmlFileUpload.click()" type="button">
              {{ "batch_jobs.batch_job_form.browse_file_button_title" | translate }}
            </button>
          </div>
          <div id="parameter">
            <input
              type="file"
              class="file-input"
              accept=".mac"
              (change)="onCredentialFileSelected($event.target.files[0])"
              #credFileUpload
            />
            <div class="file-upload-input-field">
              <mat-form-field>
                <div class="flex-display">
                  <input
                    readonly
                    matInput
                    formControlName="pmlCredential"
                    [value]="pmlCredentialsFileName"
                    placeholder="PML credentials file"
                    id="fileInput"
                  />
                  <mat-error *ngIf="!isMacFile">{{ "batch_jobs.batch_job_form.credential_file_type_error" | translate }} </mat-error>
                  <ng-container *ngIf="credError; then credErrorState; else credOkState"> </ng-container>
                  <ng-template #credOkState>
                    <div class="green-tick" *ngIf="credFileUploadSuccess">
                      <mat-icon class="greentick">{{ "batch_jobs.batch_job_form.browse_file_upload_success_icon" | translate }}</mat-icon>
                    </div>
                  </ng-template>
                  <ng-template #credErrorState>
                    <mat-icon style="color: red">{{ "batch_jobs.batch_job_form.browse_file_upload_failure_icon" | translate }}</mat-icon>
                  </ng-template>
                </div>
              </mat-form-field>
            </div>
            <button mat-stroked-button id="upload-buttons" (click)="credFileUpload.click()">
              {{ "batch_jobs.batch_job_form.browse_file_button_title" | translate }}
            </button>
          </div>
        </mat-card-content>
      </mat-card>
      <ng-container *ngIf="saveError; then errorMessage"></ng-container>
      <div id="button-segment">
        <button class="cancel" mat-raised-button (click)="onCancel()">
          {{ "cancel" | translate }}
        </button>
        <div>
          <button
            id="save-button"
            class="submit"
            mat-raised-button
            type="submit"
            (click)="onSubmit(editJobForm.value)"
            [disabled]="!form.valid"
            color="primary"
          >
            {{ "save" | translate }}
          </button>
        </div>
        <ng-template #errorMessage>
          <h4 style="color: red">{{ saveError }}</h4>
        </ng-template>
      </div>
    </form>
    <ng-template #errorPage>
      <h4>
        {{ "batch_jobs.edit_batch_job.form_details_not_available_error" | translate }}
      </h4>
    </ng-template>
  </con-page-layout-content>
</con-page-layout>
