import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import {
  ConnectCoreModule,
  ConnectCoreModuleConfig,
  AUTHENTICATION_CONFIG,
  COMPONENTS_CONFIG,
  CONNECT_API_CONFIG,
  ComponentsModuleConfig,
  ConnectApiModuleConfig,
  AuthenticationModuleConfig,
} from "@aveva/connect-web-core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { WindowRefService } from "./window-ref.service";
import { WorkspacesModule } from "./workspaces/workspaces.module";
import { AppStreamModule } from "./appstream/appstream.module";
import { BatchJobsModule } from "./batch-jobs/batch-jobs.module";
import { NotConnectAdminGuard } from "./not-connect-admin.guard";
import { TokenInterceptor } from "./token.interceptor";
import { CONFIGURATION, Configuration } from "src/configuration";
import { LaunchComponent } from "./launch/launch.component";
import { AssetResolverService } from "./asset-resolver.service";
import { UilabBaseComponentsModule } from "@uilab/base-components-angular";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "./shared/shared.module";
import { RootComponent } from "./root/root.component";
import { BatchJobsFeatureAccessGuard } from "./batch-jobs/batch-jobs-feature-access.guard";
import { BreadcrumbModule, BreadcrumbService } from "xng-breadcrumb";
import { StreamingModule } from "./streaming/streaming.module";
import { FileTransferModule } from "./filetransfer/filetransfer.module";
import { FileTransferFeatureAccessGuard } from "./filetransfer/filetransfer-feature-access.guard";

@NgModule({
  declarations: [AppComponent, LaunchComponent, RootComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    UilabBaseComponentsModule,
    ConnectCoreModule.forRoot({} as ConnectCoreModuleConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) =>
          new TranslateHttpLoader(http, "./assets/i18n/", ".json?t=" + new Date().getTime()),
        deps: [HttpClient],
      },
    }),
    WorkspacesModule,
    AppStreamModule,
    BatchJobsModule,
    FormsModule,
    SharedModule,
    BreadcrumbModule,
    StreamingModule,
    FileTransferModule,
  ],
  providers: [
    WindowRefService,
    AssetResolverService,
    NotConnectAdminGuard,
    BatchJobsFeatureAccessGuard,
    FileTransferFeatureAccessGuard,
    BreadcrumbService,
    {
      provide: AUTHENTICATION_CONFIG,
      useFactory: (configuration: Configuration) =>
        ({
          authority: configuration.authority,
          clientId: configuration.clientId,
          cachePrefix: "cloudue",
          singleSignOutUrl: configuration.singleSignOutUrl,
        } as AuthenticationModuleConfig),
      deps: [CONFIGURATION],
    },
    {
      provide: COMPONENTS_CONFIG,
      useFactory: (configuration: Configuration) =>
        ({
          appHeaderLogoPath: "", // Path to a custom logo for your capability can be specified here.
          sideNavLogoPath: "/assets/imgs/AVEVA_Logo_white_RGB.svg",
          connectLink: configuration.connect,
          appHeaderTitle: "Unified Engineering",
          termsAndConditions: "https://sw.aveva.com/legal",
          systemStatus: "OMGMGMGG",
          myProfileLink: configuration.userProfileManagementUrl,
        } as ComponentsModuleConfig),
      deps: [CONFIGURATION],
    },
    {
      provide: CONNECT_API_CONFIG,
      useFactory: (configuration: Configuration) =>
        ({
          logging: configuration.apis.logging,
          registry: configuration.apis.registry,
          am: configuration.apis.am,
          scm: configuration.apis.scm,
          uam: configuration.apis.uam,
          assets: configuration.apis.assets,
        } as ConnectApiModuleConfig),
      deps: [CONFIGURATION],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
