<con-page-layout>
  <con-page-header header="{{'workspaces.admin.page_title' | translate }}">
    <ul class="breadcrumb" *ngIf='currentFolderPath.length>0'>
      <li>{{ 'workspaces.current_folder_text' | translate}}</li>
      <li *ngFor="let path of currentFolderPath">
        <span>{{path}}</span>
      </li>
    </ul>
  </con-page-header>

  <con-page-layout-content>
    <ng-container *ngIf="error; then errorState; else okState"></ng-container>
    <ng-template #errorState>
      <div class="content-container">
        <div class="inner-container">
          <div class="warning-container greeting-container">
            <h4 class="error-page-title">
              <mat-icon class="vertical-align-middle padding-bottom-3">warning</mat-icon>
              {{'workspaces.admin.warning_title' | translate }}
            </h4>
            <p>{{error}}</p>
            <p [innerHtml]="'workspaces.admin.warning_sub_title_html' | translate "></p>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #okState>
      <div class="flex-container center" *ngIf="!loaded">
        <mat-spinner style="align-self: center;" color="primary"></mat-spinner>
      </div>

      <div class="flex-container" *ngIf="loaded">
        <div class="admin-container">
          <app-users-table #usersTable [currentUser]="currentUser" [(users)]="usersTableData" [showLinks]="false"
            [showPaging]="false" [showAddButton]="false" [removeIcon]="'remove_circle_outline'"
            (remove)="removeWorkspace($event)" (filter)="setTableData($event)">
          </app-users-table>
        </div>
      </div>
    </ng-template>
  </con-page-layout-content>

</con-page-layout>